@import "colour";

.front-header {
    height: 100%;
    background-color: $base-light;
    background-repeat: no-repeat;
    min-height: 100vh;
    position: relative;
    .links {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 0em 1em 1em;
        .icon {
            background: $base-light;
            padding: 1em;
            display: inline-block;
            border-radius: 50%;
            margin-left: 1em;
            img {
                user-drag: none;
                user-select: none;
                -moz-user-select: none;
                -webkit-user-drag: none;
                -webkit-user-select: none;
                -ms-user-select: none; } } }

    .main {
        margin: 0 auto;
        padding: 1.8em 1.4em;
        max-width: 1280px;
        .me {
            color: $me-light-dark;
            font-style: italic; }
        p {
            font-weight: normal;
            margin-top: 0;
            font-size: 1.3em;
            line-height: 1.0; }
        @media (min-width: 576px) {
            padding: 2em;
            p {
                font-size: 1.5em; } }
        @media (min-width: 768px) {
            padding-top: 3em;
            p {
                font-size: 1.7em; } }
        @media (min-width: 992px) {
            padding-top: 3.5em;
            p {
                font-size: 2em; } }
        @media (min-width: 1200px) {
            padding-top: 4em; } } }

