@import "colour";

body {
    height: 100%;
    margin: 0px;
    padding: 0px;
    font-family: 'Roboto Mono', monospace;
    font-weight: 400;
    background-color: $base-light;
    h1 {
        font-size: 6em; }
    a {
        color: $base-dark;
        text-decoration: underline;
        font-weight: 400; } }
@media (prefers-color-scheme: dark) {
    body {
        background-color: $base-dark; } }
